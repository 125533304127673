<template>
  <div class="index">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>人才互聘管理</el-breadcrumb-item>
            <el-breadcrumb-item>职位管理</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
      <el-col>
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <div class="toptitle">
                <span v-if="val1==1">招聘中</span>
                <span v-if="val1==2">已停止</span>
              </div>
              <div class="toptitle" style="font-size: 3rem;">
                {{val2}}
                <span style="color: red;font-size: 3rem;">{{val3}}-{{val4}}</span>
              </div>

            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;margin-top: 10px;">
      <el-col :span="24" style="text-align: left;margin-top: 0.7rem;">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item index="2">招聘信息</el-menu-item>
          <el-menu-item index="3">参与应聘信息</el-menu-item>
        </el-menu>
        <div class="line"></div>
        <template>
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="payTime" label="姓名">
            </el-table-column>
            <el-table-column prop="orderNo" label="投递时间">
            </el-table-column>
            <el-table-column prop="consumptionAddress" label="操作">
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="tableDataval.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="10"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {
    cities,
    CodeToText
  } from 'element-china-area-data'
  import $ from 'jquery';
  export default {
    props: ['recruitId'],
    data: function() {
      return {
        val1: '',
        val2: '',
        val3: '',
        val4: '',
        val5: '',
        val6: '',
        activeIndex: '2',
        tableData: [],
        lmbzsy: '',
        lmbzxf: '',
        unionCoin: '',
        integralAddress: '',
        // 表格字段
        tableDataval: {
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': 10,
          // 类型
          'payType': '2',
          // 用户ID
          'userId': localStorage.getItem("userId"),
        },
      }
    },
    methods: {
      handleSizeChange(val) {
        this.tableDataval.pageSize = val;
        this.getrecruitApply();
      },
      handleCurrentChange(val) {
        this.tableDataval.pageNum = val;
        this.getrecruitApply();
      },
      handleSelect(key) {
        this.tableDataval.payType = key;
        this.getrecruitApply();
      },
      // 根据招聘ID获取应聘记录接口
      getrecruitApply() {
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/recruit/recruitApply/list' + this.tableDataval.userId,
          params: this.tableDataval,
        }).then((res) => {
          this.integralAddress = res.data.data.integralAddress ? res.data.data.integralAddress : 0;
          this.unionCoin = res.data.data.unionCoin ? res.data.data.unionCoin : 0;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      }
    },
    // 页面加载完毕事件
    mounted() {
      this.getrecruitApply();
      // 根据招聘ID获取招聘详情接口
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/recruit/recruitInfo/' + this.recruitId,
        params: '',
      }).then((res) => {
        this.val1 = res.data.data.recruitStatus
        this.val2 = res.data.data.recruitTitle
        this.val3 = res.data.data.jobSalaryStart
        this.val4 = res.data.data.jobSalaryEnd
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });

    }
  }
</script>

<style src="@/style/Team/Position2.css" scoped></style>
<style>
  ::-webkit-scrollbar {
    display: none
  }
</style>
